import { AuthUrls, get_base_url } from '../constants/urls'
import axios from './axios'

//Authentication
export const get_tenant = async (onSuccess, onFail, values, callback, loginPrompt) => {
    const url = AuthUrls.GET_TENANT
    try {
        const { data } = await axios.post(url, values)
        onSuccess(data, callback, loginPrompt)
    }
    catch (e) {
        onFail(e, loginPrompt)
    }

}

export const do_login = async (onSuccess, onFail, values, tenant, loginPrompt) => {
    const url = get_base_url(tenant) + AuthUrls.LOGIN
    try {
        const { data } = await axios.post(url, values)
        onSuccess(data, loginPrompt)
    }
    catch (e) {
        onFail(e, loginPrompt)
    }
}

export const refresh_token = async (onSuccess, onFail, tenant, values) => {
    const url = get_base_url(tenant) + AuthUrls.LOGIN_REFRESH
    try {
        const data = await axios.post(url, { refresh: values })
        onSuccess(data)
    }
    catch (e) {
        onFail(e)
    }
}


