import React, { useContext } from 'react';
// import { UserStoreContext } from '../../stores/UserStore'
import { observer } from 'mobx-react';
// import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { UIStoreContext } from '../../stores/UIStore';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toaster = observer((props) => {
  // const UIStore = useContext(UI)
  const uiStore = useContext(UIStoreContext)
  // const [open, setOpen] = useState(uiStore.toasterOpen);

  // const position = {
  //     vertical: "top",
  //     horizontal: "center"
  // }


  // const handleClick = () => {
  //     uiStore.openToaster()
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    uiStore.closeToaster()
  };


  return (
    <div>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        // style={{fontSize:"1"}}
        open={uiStore.toasterOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}

      >
        <Alert onClose={handleClose} severity={uiStore.toasterSeverity} style={{ fontSize: "18px" }}>
          {uiStore.toasterBody}
        </Alert>
      </Snackbar>
    </div>
  );
})

export default Toaster;