import styled from "styled-components";
import { Label, DropdownToggle } from 'reactstrap';
import { NavLink } from "react-router-dom";


export const MainContainer = styled.div`
display: flex;
flex-direction: column;
/* background-color: black; */
/* flex:4; */
height: 100vh;
`

export const MainBodyContainer = styled.div`
display: flex;
flex-direction: row;
flex:1;
min-width:1400px;
`

export const SideMenuContainer = styled.div`
display: flex;
flex-direction: column;
background-color: ${(props) => props.theme.colors.bg};
padding:15px;
/* flex:1 */
/* height: 100vh; */
`
export const MainBody = styled.div`
display: flex;
flex-direction: column;
flex:12;
background-color: ${(props) => props.theme.colors.bgBody};
align-items: center;
height: 100vm;
/* justify-content: center; */


/* flex:1 */
/* height: 100vh; */
`

export const SideMenuHeader = styled.div`
cursor:pointer;
display: flex;
align-items: center;
margin:"0 auto"; 
justify-content:space-between; 
color: rgb(252, 238, 238);
min-width: 170px;
&.active{
    color: rgb(148, 60, 60);
}
  &:hover{
    color: brown;
    }

/* flex:1 */
/* height: 100vh; */
`
export const LabelSideBar = styled(Label)`
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:10px;

`
export const LinkSideBar = styled(NavLink)`
    display:block;
    color: rgb(155, 105, 105);
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size:10px;
    &:hover{
    color: brown;
    text-decoration: none;
    }
    &.active{
        text-decoration: none;
        color: rgb(250, 244, 244);
    }
    &:not(:last-child){
        margin-bottom:30px;
    }

`
export const WrapperLinks = styled.div`
width:100%;
padding:20px 20px 20px 30px;
`

export const DropdownToggleSideBar = styled(DropdownToggle)`
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    &:hover{
    color: brown;
    }
`