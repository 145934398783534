import styled, { createGlobalStyle } from "styled-components";
import { Col } from 'reactstrap';
import { css } from "@emotion/react";

export const theme = {
  colors: {
    primary: '#051C2C',
    secondary: '#A7A8A9',
    secondaryShade: '#ce5558',
    bg: '#051C2B',
    bgBody: '#F3F3F3',
    btnPrimary: "#BF0D3E",



    toast: {
      info: '#1cbcd8',
      success: '#33cc66',
      warning: '#fdb913',
      danger: '#ff3333'
    }
  }
}

export const ColWithFixedWidth = styled(Col)`
width:1200px;
`
export const override = css`
  display: block;
  margin: 300px auto auto auto;
  border-color: red;
`;

export const MyCustomContainer = styled.div`
margin: 0 auto 0 auto;
padding: 0 15px 0 15px;
`
export const MyHeaderPages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 5px 0px;
`
export const OnBreadCrumbStyle = {
  width: '100%',
  border: '1px solid #00000020',
  padding: '3px 0px 3px 10px',
}
export const OnTextBredCrumb = { fontSize: 11, cursor: 'pointer', fontWeight:600, letterSpacing:'-0.5px' }
export const onHeadlines = {
  marginTop:0,
  color: '#051c2b',
  fontFamily: "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
}
export const GlobalStyles = createGlobalStyle`
import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

* {
    box-sizing: border-box
    }

body {
    font-family: Roboto sans-serif;
    font-size: 1.15em;
    margin: 0;
}
`