export const reports = [{
    "reportId": "1",
    "reportName": "Example_El_Report",
    "reportLayout": [
        {
            "key": "summary",
            "cover": true,
            "headerTop": "10 content assents have been harvested and analyzed. identified 15 new topics, 4 new formats, 5 new styles",
            "titleLeft": { logo: true },
            "titleRight": "Date: 20/08/2021",
            "bodyTopLeft": { "key": "market_map" },
            "bodyTopRight": { "key": "market_table" },
            "footerLeft": ["Since August 2021, Contrend has completed research projects for SC in 1 Market"],
            "footerRight": ["Contrend reseach has identified a total of 15 NEW topics, 5 NEW styles and 4 NEW Formats that were not previously being deployed by SC"]
        },
        {
            "key": "summary",
            "titleLeft": "Competitive Landscape Share",
            "subtitleCenter": "Competative Landscape Relates to 0.1% Share of Total Landscape",
            "bodyTopLeft": { "key": "competitive_landscape_share" },
            "bodyTopRight": { "key": "competitive_landscape_share_line" },
            "footerLeft": ["SC has 38.0% of competative Share of Voice (SOV) about ESG in Singapore"]
        },
        {
            "key": "comps_vs_landscape",
            "titleLeft": "SC & Comps vs Landsacape",
            "bodyTopLeft": { "key": "sc_and_comps_donut" },
            "bodyTopRight": { "key": "sc_and_comps_line" },
            "footerLeft": ["Industry engagement (from content published by SC and UOB, Citi OCBC, DBS) i s0.1% of total landscape engagemnet about ESG",
                "The remaining 99.9% of audience engagement in Singapore about ESG has been generated by publishers who are not SC or UOB, Citi, OCBC, DBS"]
        },
        {
            "titleLeft": "Total Market Topics",
            "bodyTopLeft": { "key": "total_market_topics" },
            "footerLeft": ["3 topics deliver 27.6% of total Landscape engagemnet (Ei): Climate and Carbon Sustainability initiatives, Cryptocurrency and Clean Energy, Enviornmental Taxes"]
        },
        {
            "bodyTopLeft": { "key": "total_market_styles" },
            "bodyTopRight": { "key": "total_market_formats" }
        },
        {
            "bodyTopLeft": { "key": "topic_group_contribution" },
            "footerLeft": ["Top 3 topics climate and Carbon Sustainability initiatives, Cryptocurrency and Clean Energy, Enviornmanetal Taxes deliver 27.6% of engagemnet in the landscape; Top 3 Topics Sustainable investment Themes - ESG,ESG Strategy deliver 76.5% of engagement in your industry (SC and UOB, Citi, OCBC, DBS)"]
        },
        {
            "bodyTopLeft": { "key": "sc_and_comps_landscape_style" },
            "bodyTopRight": { "key": "sc_and_comps_landscape_style" },
            "bodyBottomLeft": { "key": "sc_and_comps_landscape_format" },
        },
        {
            "bodyTopLeft": { "key": "competitor_landscape_topic" },
        },

    ],
    "reportData": [
        {
            "key": "market_map",
            "chartType": "image",
            "link": "https://source.unsplash.com/random/600x400"
        },
        {
            "key": "market_table",
            "chartType": "tableChart",
            "data": [{
                "Market": "Singapore",
                "Research Subject": "ESG",
                "Assets": 52118,
                "Topics": 19,
                "Styles": 6,


            },
            {
                "Market": "Singapore",
                "Research Subject": "ESG",
                "Assets": 52118,
                "Topics": "lalalal",
                "Styles": 6,


            }]
        },
        {
            "key": "competitive_landscape_share",
            "chartType": "stackedColumn",
            "legend": false,
            "min": 0,
            "max": 50,
            "xAxis": ["share"],
            "yAxis": "Engagement Index (EI)",
            "imagePosition": { "x": 50, "y": -20 },
            "series": [
                {
                    "name": "SC",
                    "data": [38]
                },
                {
                    "name": "Citi",
                    "data": [25.1],
                    "color": "grey",
                    "image": "https://source.unsplash.com/random/40x40"
                },
                {
                    "name": "OCBC",
                    "data": [15.9]
                },
                {
                    "name": "DBS",
                    "data": [11.9],
                    // "image":"https://source.unsplash.com/random/100x60"
                },
                {
                    "name": "UOB",
                    "data": [9.1]
                }
            ]
        },
        {
            "key": "competitive_landscape_share_line",
            "chartType": "lineChart",
            "title": "competitive landscape share",
            // "subtitle":"Competitive Landscape Relates to 0.1% Share of Total Landsacape",
            "legend": true,
            "min": 0,
            "max": 50,
            "xAxis": {
                "categories": ["Aug-2021"]
            },
            "yAxis": "Engagement Index (EI)",
            "series": [
                {
                    "name": "SC",
                    "data": [38]
                },
                {
                    "name": "Citi",
                    "data": [25.1,]
                },
                {
                    "name": "OCBC",
                    "data": [15.9]
                },
                {
                    "name": "DBS",
                    "data": [11.9]
                },
                {
                    "name": "UOB",
                    "data": [9.1]
                }
            ]
        },

        {
            "title": "",
            "key": "sc_and_comps_donut",
            "chartType": "donutChart",
            "innerSize": "60%",
            "legend": true,
            "dataLabels": {
                enabled: false
            },
            "data": [
                { name: 'Landscape', y: 99.9 },
                { name: 'SC & Comps', y: 0.1 }
            ]
        },
        {
            "key": "sc_and_comps_line",
            "chartType": "lineChart",
            "legend": true,
            "xAxis": {
                "categories": ["Aug-2021"]
            },
            "yAxis": "Engagement Index (EI)",
            "series": [
                {
                    "name": "Landscape",
                    "data": [99.9]
                },
                {
                    "name": "SC and Comps",
                    "data": [0.1,]
                }
            ]
        },
        {
            "key": "total_market_topics",
            "title": "",
            "chartType": "barChart",
            "legend": true,
            "min": 0,
            "max": 10,
            "yAxis": "Engagement Index (EI)",
            "dataLabels": {
                enabled: false
            },
            "data": {
                "Climate And Carbon Sustainability initiatives": 11.9,
                "Cryptography And Clean Energy": 7.9,
                "Enviornmental Taxes": 7.8,
                "Inestment Events/Forums": 7.7,
                "Green Financial Instruments": 7.3,
                "ESG Company Importance": 7.2,
                "Impact Investing - Funds And Funding": 6.8,
                "ESG Governance": 6.3,
                "Investment Themes - ESG": 5.9,
                "Investment Community Social Impact": 5.6,
                "ESG Strategy": 5.1,
                "Millenial Spending (Climate Change)": 4.9,
                "SRI - Socially Responsible Investing": 4.9,
                "ESG Measurability": 3.3,
                "Investing Different Forms Of Energy": 2.2,
                "Sustainable-Finance Regulations": 1.8,
                "Sustainable Investment Trends": 1.5,
                "Task Force On Climate-Related Financial Disclosures | TCFD": 1,
                "Technology Investments": 1,
            }
        },
        {
            "key": "total_market_styles",
            "title": "Total Market Styles",
            "chartType": "barAndLineChart",
            "legend": true,
            "min": 0,
            "max": 40,
            "yAxis": "Engagement Index (EI)",
            "dataLabels": {
                enabled: false
            },

            data: [
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    yAxis: 1,
                    data: {
                        "interview": 30,
                        "Opinion": 23,
                        "Education": 22,
                        "Trends": 22,
                        "News": 21,
                        "Tips And Advice": 20
                    },
                    color: "red"

                }, {
                    name: 'Oppertunity Index (Oi)',
                    type: 'spline',
                    color: "green",
                    data: {
                        "interview": 35,
                        "Opinion": 30,
                        "Education": 28,
                        "Trends": 28,
                        "News": 11,
                        "Tips And Advice": 37
                    },
                }
            ]
        },
        {
            "key": "total_market_formats",
            "title": "Total Market Format",
            "chartType": "barAndLineChart",
            "legend": true,
            "yAxis": ["Engagement Index (EI)", "Oppertunity Index (Oi)"],
            "dataLabels": {
                enabled: false
            },

            data: [
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    yAxis: 1,
                    data: {
                        "Article (3-10 Min Read)": 25,
                        "Article (3 Min Read)": 22,
                        "Article (Above 10 Min Read)": 31,
                        "Video (Video Long)": 39,
                        "Video (Video Medium)": 24,
                        "Video (Video short)": 21,
                    },
                    color: "red"

                }, {
                    name: 'Oppertunity Index (Oi)',
                    type: 'spline',
                    color: "green",
                    data: {
                        "Article (3-10 Min Read)": 20,
                        "Article (3 Min Read)": 17,
                        "Article (Above 10 Min Read)": 30,
                        "Video (Video Long)": 33,
                        "Video (Video Medium)": 30,
                        "Video (Video short)": 29.5,
                    },
                }
            ]
        },
        {
            "key": "competitor_landscape_topic",
            "title": "Competitor Landscape: Topic",
            "chartType": "spiderChart",
            "xAxis": ["1", "2", "3", "4", "5"],
            "legend": true,
            "yAxis": ["Engagement Index (EI)", "Oppertunity Index (Oi)"],
            "dataLabels": {
                enabled: false
            },

            data: [
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    yAxis: 1,
                    data: {
                        "Article (3-10 Min Read)": 25,
                        "Article (3 Min Read)": 22,
                        "Article (Above 10 Min Read)": 31,
                        "Video (Video Long)": 39,
                        "Video (Video Medium)": 24,
                        "Video (Video short)": 21,
                    },
                    color: "red"

                }, {
                    name: 'Oppertunity Index (Oi)',
                    type: 'spline',
                    color: "green",
                    data: {
                        "Article (3-10 Min Read)": 20,
                        "Article (3 Min Read)": 17,
                        "Article (Above 10 Min Read)": 30,
                        "Video (Video Long)": 33,
                        "Video (Video Medium)": 30,
                        "Video (Video short)": 29.5,
                    },
                }
            ]
        },
        {
            "key": "topic_group_contribution",
            "title": "topic group contribution",
            "chartType": "stackedColumn",
            "legend": true,
            "min": 0,
            "max": 50,
            "xAxis": ["Landscape", "SC & Copms"],
            "yAxis": "Engagement Index (EI)",
            "imagePosition": { "x": 0, "y": 0 },
            "series": [
                {
                    "name": "Sustainabilty Investment Trends",
                    "data": [1, 45]
                },
                {
                    "name": "ESG Energy",
                    "data": [5.1, 13.6],
                    "color": "yellow",
                },
                {
                    "name": "OCBC",
                    "data": [15.9],
                    "color": "blue",
                    "image": "https://source.unsplash.com/random/20x20"
                },
                {
                    "name": "DBS",
                    "data": [11.9],
                },
                {
                    "name": "UOB",
                    "data": [9.1]
                }
            ]
        },
        {
            "key": "sc_and_comps_landscape_style",
            "title": "sc &_ omps landscape style",
            "chartType": "barAndLineChart",
            "legend": true,
            "min": 0,
            "max": 40,
            "yAxis": "Engagement Index (EI)",
            "dataLabels": {
                enabled: false
            },

            data: [
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    data: {
                        "interview": 30,
                        "Opinion": 23,
                        "Education": 22,
                        "Trends": 22,
                        "News": 21,
                        "Tips And Advice": 20
                    },
                    color: "red"

                },
                {
                    name: 'SC and Comps',
                    type: 'column',
                    data: {
                        "interview": 30,
                        "Opinion": 23,
                        "Education": 22,
                        "Trends": 22,
                        "News": 21,
                        "Tips And Advice": 20
                    },
                    color: "purple"

                }, {
                    name: 'Oppertunity Index (Oi)',
                    type: 'spline',
                    color: "green",
                    data: {
                        "interview": 35,
                        "Opinion": 30,
                        "Education": 28,
                        "Trends": 28,
                        "News": 11,
                        "Tips And Advice": 37
                    },
                }
            ]
        },
        {
            "key": "sc_and_comps_landscape_format",
            "title": "sc & comps landscape format",
            "chartType": "barAndLineChart",
            "legend": true,
            "min": 0,
            "max": 40,
            "yAxis": "Engagement Index (EI)",
            "dataLabels": {
                enabled: false
            },

            data: [
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    yAxis: 1,
                    data: {
                        "interview": 30,
                        "Opinion": 23,
                        "Education": 22,
                        "Trends": 22,
                        "News": 21,
                        "Tips And Advice": 20
                    },
                    color: "red"

                },
                {
                    name: 'Engagement Index (EI)',
                    type: 'column',
                    // yAxis: 1,
                    data: {
                        "interview": 30,
                        "Opinion": 23,
                        "Education": 22,
                        "Trends": 22,
                        "News": 21,
                        "Tips And Advice": 20
                    },
                    color: "purple"

                }, {
                    name: 'Oppertunity Index (Oi)',
                    type: 'spline',
                    color: "green",
                    data: {
                        "interview": 35,
                        "Opinion": 30,
                        "Education": 28,
                        "Trends": 28,
                        "News": 11,
                        "Tips And Advice": 37
                    },
                }
            ]
        },



    ]


},
{
    "reportId": "2"
},
{
    "reportId": "3"
}
]