/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { UserStoreContext } from '../../stores/UserStore'
import './style/style.css'
import { Button, Col, Row, Card, CardBody } from "reactstrap";
import InputComponent from './components/inputComponents';
import { LoginContainer } from './style/Container.styled';
import redLogo from '../../assets/logo/Contrend-logo-red.png'
// import LogoPoweredImmedia from '../../assets/img/powered-by-immedia-content.png';
import Toaster from '../Global/Toaster'
import { UIStoreContext } from '../../stores/UIStore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  NavLink,
  Redirect
} from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { override } from '../../style/Global';
import { LinksSideBar } from '../../constants/urls';
import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

const meEnv = dotenv.config()
dotenvExpand(meEnv)

const mode = process.env.REACT_APP_PRODUCTION_MODE ? JSON.parse(process.env.REACT_APP_PRODUCTION_MODE) : false




const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  password: Yup.string().required("Password is required!")
});

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

//const clearCacheData = () => {
//  caches.keys().then((names) => {
//    names.forEach((name) => {
//      caches.delete(name);
//    });
//  });
//};

const Login = observer(() => {
  const userStore = useContext(UserStoreContext)
  const uiStore = useContext(UIStoreContext)

  const { email } = useState('')
  const { password } = useState('')
  const [submitting, setSubmitting] = React.useState(false);
  const [tenant, setTenant] = React.useState(null);

  useEffect(() => {
    localStorage.clear()
    deleteAllCookies()
    //clearCacheData()

  }, [submitting])

  if (userStore.isAuthenticated) {
    return <Redirect push to={mode ? LinksSideBar.reports : LinksSideBar.reports} />
  }

  const LoginPrompt = (severity, body, isAuthenticated = false) => {
    uiStore.toasterSeverity = severity
    uiStore.toasterBody = body
    uiStore.openToaster()
    setSubmitting(false)
  }

  const selectTenant = (newTenant) => {
    userStore.tenant = newTenant
    setTenant(newTenant)
  }



  const welcomeMsg = "Welcome! Please log-in to your account"
  return (
    <>
      {submitting ? <RingLoader css={override} /> : <LoginContainer>

        <Card style={{ minWidth: "900px", minHeight: "400px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div style={{ padding: "20px", flex: 1, width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <img alt="regLogo" src={redLogo} style={{ width: "150px", height: "32px" }} />
          </div>
          <div style={{ flex: 1, width: "80%", display: "flex", justifyContent: "center" }}>
            <h3 style={{ fontFamily: "Segoe UI,Roboto,Helvetica Neue,Arial" }} className="heading">
              {welcomeMsg.toUpperCase()}
            </h3>
          </div>
          <div style={{ flex: 8, width: "80%" }}>
            <Formik
              initialValues={{ email: "", password: "" }}
              // enableReinitialize={true}
              validationSchema={validationSchema}
              // onSubmit={(values) => doLogin(values)
              onSubmit={(values,) => {
                setSubmitting(true)
                if (userStore.tenant) {
                  userStore.getToken(values, LoginPrompt)
                }
                else {
                  userStore.getTenant(values, LoginPrompt)
                }
              }}
            >
              {({ isSubmitting, handleBlur, errors, touched, values }) => (
                <Form>
                  <InputComponent
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    showIcon={true}
                    iconName={"fa fa-envelope"}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    value={email}
                  />
                  <InputComponent
                    isPassword={true}
                    type="password"
                    name="password"
                    id="password"
                    showIcon={true}
                    iconName={"fa fa-lock"}
                    placeholder="Password"
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    value={password}
                  />
                  <Row className="mb-sm-4">

                    <Col sm={3} className="forgot-link">
                      <p>
                        <NavLink to="/forgot-password">
                          Forgot Password?
                        </NavLink>
                      </p>
                    </Col>
                    <Col sm={3} className="forgot-link">
                      {
                        userStore.tenants.length > 1 ?
                          <FormControl
                            variant="outlined"
                            margin={"1"}
                            style={{}}>
                            <InputLabel id="reports-filter-year-label" style={{ backgroundColor: "white", fontSize: "14px", paddingLeft: " 5px", paddingRight: "5px" }}>
                              Log in As Client:</InputLabel>
                            <Select
                              // style={{height:"0px"}}
                              id="reports-filter-year-select"
                              value={tenant}
                              onChange={(event) => { selectTenant(event.target.value) }}

                              input={<OutlinedInput label="year" />}
                              style={{ width: "180px" }}
                            // MenuProps={MenuPropsJoined}
                            >
                              {userStore.tenants.length > 1 ?
                                userStore.tenants.map((tenant, id) => (
                                  <MenuItem
                                    key={id}
                                    value={tenant}
                                    style={{ fontSize: "20px" }}
                                  // style={getStyles(market, personName, mtheme)}
                                  >
                                    {tenant}
                                  </MenuItem>
                                ))

                                : null
                              }
                            </Select>
                          </FormControl>
                          : null
                      }

                    </Col>
                    <Col sm={6}>
                      <Button
                        type="submit"
                        disabled={submitting}
                        block
                        className="btn-common mb-0"
                        size="lg"
                      >
                        Sign in Now
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Card>

        <Card style={{ minWidth: "900px", display: "flex", flexDirection: "column" }}>
          <CardBody>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                <div style={{ margin: "0px 10px 0px 10px" }}>
                  ©2022 IMMedia Holdings. All Rights Reserved
                </div>
                <div style={{ margin: "0px 10px 0px 10px" }}>
                </div>

              </div>


            </div>

          </CardBody>
        </Card>
        <Toaster />
      </LoginContainer>}

    </>
  );
})

export default Login
