import { observable, makeObservable, configure, runInAction } from "mobx"
import { createContext } from "react"
import { get_reports, get_reports_filter } from "../api/ReportApi";

//mock data for development
import { reports as mockReports } from './mock/reportData';

configure({
  enforceActions: "never",
})

class ReportStore {
  report = {}
  reports = []
  sections = []
  markets = []
  subjects = []
  months = []
  years = []


  constructor() {
    makeObservable(this, {
      reports: observable,
      sections: observable,
      markets: observable,
      subjects: observable
    });
  }

  getReport(reportId) {
    //TODO get report data from server
    //mock data
    const reports = mockReports
    reports.map((report, id) => {
      if (reportId === report.reportId) {
        return this.report = report
      }
      return false
    })

    //test spinner loading
    //   this.report={}
  }


  getFilter = async (token, markets, subjects, month, year, callback, tenant) => {

    let filter = {
      markets: markets,
      subjects: subjects,
      months: [month],
      years: [year]
    }

    const onSuccess = (res, callback) => {
      if (res.options) {
        this.markets = res.options.markets
        this.subjects = res.options.subjects
        this.months = res.options.months
        this.years = res.options.years
      }
      callback(false)
    }
    const onFail = (res) => {
      console.log('fail from get filter')
      console.log(res)
      //TODO show error
    }

    // return await get_tenant(onSuccess, onFail, values, this.getToken)
    return await get_reports_filter(token, onSuccess, onFail, filter, callback, tenant)
  }

  load_report_sections = (json) => {
    let temp = {
      "report_id": json["id"],
      "reportLayout": json['content']['reportLayout'],
      "reportData": json['content']['reportData']
    }
    let temp_sections = {
      'summary': [],
      'customer_comps_vs_landscape': [],
      'customer_vs_landscape': [],
      'competitor_benchmarking': [],
      'landscape_analysis': [],
      'recommendations': [],
      'predictions': [],
      'all': json['content']['reportLayout']
    }
    if (temp["reportLayout"].length > 0) {
      temp["reportLayout"].map((page) => {
        if ('key' in page && page['key'] in temp_sections) {
          return temp_sections[page['key']].push(page)
        }
        return false
      })
      this.sections = temp_sections
    }
    return temp
  }

  getReports = async (token, markets, subjects, month, year, callback, tenant) => {

    let values = {}

    if (markets && markets.length > 0) {
      values['markets'] = markets;
    }
    if (subjects && subjects.length > 0) {
      values['subjects'] = subjects;
    }
    if (month) {
      values['month'] = month.substring(0, 3).toLowerCase();
    }
    if (year) {
      values['year'] = year;
    }


    const onSuccess = (res, callback) => {
      if (res.reports && res.reports.length > 0) {
        this.reports = res.reports
        this.report = res.reports[0]
        this.load_report_sections(this.report)
        callback(res.reports)
      }

      else {
        this.report = {}
      }


      // callback(values)

    }
    const onFail = (res) => {
      console.log(res)
      console.log('fail in getReports')
      //TODO show error
    }

    // return await get_tenant(onSuccess, onFail, values, this.getToken)
    return await get_reports(token, onSuccess, onFail, values, callback, tenant)
  }

  getChartData(key) {
    // gets report data for key
    this.report.reportData.map((data) => {
      console.log(data)
      if (key === data.key) {
        return (data)
      }
      return false
    })
  }

  clearReport(){
    runInAction(() => {
      this.report = {}
      this.reports = []
    })
  
  }
}


export const ReportStoreContext = createContext(new ReportStore());
export default new ReportStore();
