import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import { UserStoreContext } from '../../../stores/UserStore'
import { ReportStoreContext } from '../../../stores/ReportStore'
import {LinksSideBar} from '../../../constants/urls'
import {
    Navbar,

    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label
} from 'reactstrap';
import { Bell } from "react-feather";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import avatarIcon from '../../../assets/img/default-avatar.png'
import ContrendLogo from '../../../assets/logo/Contrend-Logo.png'
import { theme } from '../../../style/Global'
import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

const meEnv = dotenv.config()
dotenvExpand(meEnv)

const mode = process.env.REACT_APP_PRODUCTION_MODE ? JSON.parse(process.env.REACT_APP_PRODUCTION_MODE) : false






const TopNavBar = observer(() => {
    const userStore = useContext(UserStoreContext)
    const reportStore = useContext(ReportStoreContext)
    const history = useHistory()

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    //TODO Fix onCollapse hide items

    return (
        <>
            <Navbar style={{ margin: 0, backgroundColor: theme.colors.bg, borderRadius: 0, minWidth: '1433px', minHeight: 60 }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                }} >
                    <div style={{display:'flex', alignItems:'center'}}>

                   <NavLink onClick={() => history.push(mode ? LinksSideBar.reports : LinksSideBar.link)}><img style={{ width: "150px", height: "30px", cursor: 'pointer' }} src={ContrendLogo} alt='' /></NavLink>
                        <span style={{color:'white'}}>Connected: {userStore?.tenant?.toUpperCase()}</span>
                    </div>

                    <div>
                    

                        <Nav navbar style={{ display: "flex", flexDirection: "row", alignItems: "center", height: '50px', margin: 0 }}>


                        <NavItem key="user-guide" style={{ cursor: "pointer" }}>
                            <div style={{ height: "35px", cursor: "pointer" }}>
                                <NavLink onClick={() => history.push(LinksSideBar.userGuide)}><Label style={{ color: "white", whiteSpace: "nowrap", verticalAlign: "baseline", cursor: "pointer" }}>User Guide</Label></NavLink>
                            </div>
                        </NavItem>
                        <NavItem key="notifications" style={{ cursor: "pointer" }}>
                            <NavLink onClick={() => history.push(LinksSideBar.notifications)}>
                                <Bell size={21} color={theme.colors.secondary} style={{ margin: "10px", width: "35px", height: "35px", borderRadius: "50%" }} />
                            </NavLink>
                        </NavItem>
                        <NavItem key="avatar-item" style={{ cursor: "pointer" }}>

                            <UncontrolledDropdown isOpen={isOpen} toggle={toggle}>

                                <DropdownToggle tag="span" >
                                    <img alt="avatar" onClick={toggle} src={avatarIcon} style={{ margin: "10px", verticalAlign: "middle", width: "35px", height: "px", borderRadius: "50%" }} />
                                                </DropdownToggle>

                                    {/* <DropdownToggle>
                        
                    </DropdownToggle> */}
                                    <div style={{
                                        position: 'absolute',
                                        willChange: 'transform',
                                        top: '19px',
                                        left: '0px',
                                        zIndex: 100,
                                        paddingTop: 5
                                    }}>
                                        <DropdownMenu right
                                            style={{ width: 250, fonSize: 14 }}
                                        >
                                            <DropdownItem> <span style={{ marginLeft: 5 }}>{userStore.tenant && userStore.tenant[0].toUpperCase() + userStore.tenant.slice(1)}</span> </DropdownItem>
                                            <DropdownItem divider />
                                                        <DropdownItem  disabled={mode} onClick={() => history.push(LinksSideBar.myProfile)}> <PersonOutlineOutlinedIcon style={{ marginRight: 7 }} />My Profile</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem disabled={mode} onClick={() => history.push(LinksSideBar.changePassword)} style={{ marginBottom: 5 }} > <LockOutlinedIcon style={{ marginRight: 7 }} />Change Password</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                userStore.removeToken()
                                                reportStore.clearReport()
                                                }}><LogoutIcon style={{ margin: '0px 3px 0px 2.5px' }} /> Logout</DropdownItem>
                                        </DropdownMenu>
                                    </div>
                                </UncontrolledDropdown>
                            </NavItem>

                        </Nav>
                    </div>
                </div>
            </Navbar >
        </ >
    )
})

export default TopNavBar;