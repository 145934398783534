import axios from 'axios';
import userStore from '../stores/UserStore';
import reportStore from '../stores/ReportStore';
import { AuthUrls, PROTOCOL, BASE_URL } from '../constants/urls';



const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async (e) => {
    const tenant = localStorage.getItem('tenant')
    const originalRequest = e.config;
    if (e.response.status === 401 && originalRequest.url === `${PROTOCOL}${tenant}.${BASE_URL}/${AuthUrls.LOGIN_REFRESH}`) {
        reportStore.clearReport();
        userStore.removeToken();
        return Promise.reject(e);
    }
    if (e.response.status === 401 && originalRequest.url === `${PROTOCOL}${null}.${BASE_URL}/${AuthUrls.LOGIN_REFRESH}`) {
        reportStore.clearReport();
        userStore.removeToken();
        return Promise.reject(e);
    }
    if (e.response.status === 400 && originalRequest.url === `${PROTOCOL}${tenant}.${BASE_URL}/${AuthUrls.LOGIN_REFRESH}`) {
        reportStore.clearReport();
        userStore.removeToken();
        return Promise.reject(e);
    }
    if (e.response.status === 400 && originalRequest.url === `${PROTOCOL}${null}.${BASE_URL}/${AuthUrls.LOGIN_REFRESH}`) {
        reportStore.clearReport();
        userStore.removeToken();
        return Promise.reject(e);
    }

    if (e.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        try {
            const data = await userStore.refreshToken()
            return () => {
                if (data.status === 200) {
                    userStore.setToken(data.data.access);
                    axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.access;
                    return axiosApiInstance(originalRequest);
                }
            }
        } catch (e) {
            console.log(e.message)
        }

    }
    return Promise.reject(e);
});

export default axiosApiInstance
