import { observable, makeObservable, autorun, runInAction } from "mobx"
import { createContext } from "react"
import { do_login, get_tenant, refresh_token } from "../api/UserApi";


const STORAGE_KEYS = {
  token: 'token',
  refresh_Token: 'refresh_token',
  tenant: 'tenant'
}

class UserStore {
  token = null
  refresh_Token = null
  isAuthenticated = null
  username = ''
  is_superuser = ''
  detail = ''
  tenant = null
  tenants = []



  constructor() {

    makeObservable(this, {
      isAuthenticated: observable,
      username: observable,
      is_superuser: observable,
      detail: observable
    });
    autorun(async () => {
      this.token = localStorage.getItem(STORAGE_KEYS.token);
      this.refresh_Token = localStorage.getItem(STORAGE_KEYS.refresh_Token);
      this.tenant = localStorage.getItem(STORAGE_KEYS.tenant);

      if ((this.token || this.refresh_Token) && this.tenant && this.tenant !== "undefined") {
        runInAction(() => {
          this.isAuthenticated = true
        })

      }
      else {
        runInAction(() => {
          this.isAuthenticated = false
        })

      }

    });
  }
  // async getStoredData() {
  //   return getPersistedStore(this);
  // }
  //Authentication Management
  toggelIsAuth = () => {
    runInAction(() => {
      this.isAuthenticated = !this.isAuthenticated
    })
  }
  getToken = async (values, loginPrompt) => {

    try {
      const onSuccess = (res) => {

        //set token to store and localstorage
        localStorage.setItem(STORAGE_KEYS.token, res.access);
        localStorage.setItem(STORAGE_KEYS.refresh_Token, res.refresh);
        localStorage.setItem(STORAGE_KEYS.tenant, this.tenant);



        this.username = res.username
        this.is_superuser = res.is_superuser
        runInAction(() => {
          this.isAuthenticated = true
        })


        // window.location.reload(false)


        // loginPrompt("success", "Logged In Successfully", true)
      }
      const onFail = (res) => {

        runInAction(() => {
          this.isAuthenticated = false
        })
        this.detail = res.detail
        loginPrompt("error", "Wrong Email or Password")

      }
      if (this.tenant) {

        await do_login(onSuccess, onFail, values, this.tenant)
      }
    }
    catch (e) {
      console.log(e.message)
    }
  }

  getTenant = async (values, loginPrompt) => {
    try {
      const onSuccess = (res, callback, loginPrompt) => {
        //if email has more than 1 account
        if (res.tenants.length > 1) {

          this.tenant = null
          this.tenants = res.tenants
          loginPrompt("success", "found multiple accounts for email")
        }
        else {
          this.tenant = res.tenants[0]
          localStorage.setItem(STORAGE_KEYS.tenant, this.tenant);
          // console.log(this.tenant)
          callback(values, loginPrompt)
        }

      }
      const onFail = (res, loginPrompt) => {
        this.tenant = null
        console.log(res)

        loginPrompt("warning", "email account not found")


        //TODO show error
      }

      return await get_tenant(onSuccess, onFail, values, this.getToken, loginPrompt)
    }
    catch (e) {
      console.log(e.message)
    }


  }

  refreshToken = async () => {
    try {
      const onSuccess = (res) => {

        localStorage.setItem(STORAGE_KEYS.token, res.data.access);

        runInAction(() => {
          this.isAuthenticated = true
        })

      }
      const onFail = (res) => {
        this.removeToken()
        this.detail = res.detail

        //TODO show error
      }
      this.refresh_Token = localStorage.getItem(STORAGE_KEYS.refresh_Token);
      this.tenant = localStorage.getItem(STORAGE_KEYS.tenant);


      return await refresh_token(onSuccess, onFail, this.tenant, this.refresh_Token)

    }
    catch (e) {
      console.log(e.message)
    }
  }

  removeToken = () => {
    runInAction(() => {
      this.isAuthenticated = false
    })
      this.token = null
      this.refresh_Token = null
    this.tenant = null
    localStorage.removeItem(STORAGE_KEYS.token);
    localStorage.removeItem(STORAGE_KEYS.refresh_Token);
    localStorage.removeItem(STORAGE_KEYS.tenant);
    window.location.reload()
  }

  getAllTokens = () => {
    const token = this.token
    const refToken = this.refresh_Token
    return {
      token,
      refToken
    }
  }
  setToken = (token) => {
    this.token = token
    localStorage.setItem(STORAGE_KEYS.token, token);
  }

  checkToken = (token) => {
    if (token !== "") {
      return true
    }
  }


}
export default new UserStore()
export const UserStoreContext = createContext(new UserStore());

