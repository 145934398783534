import { observable, makeObservable } from "mobx"
import { createContext } from "react"


export class UIStore {
    toasterOpen = false
    toasterBody = ""

    // info | warning | error | success
    toasterSeverity = 'info'



    constructor() {

        makeObservable(this, {
            toasterOpen: observable,
            toasterBody: observable,
            toasterSeverity: observable
        });

        // console.log("UIStore constructor")

    }

    openToaster = () => {
        this.toasterOpen = true
    }

    closeToaster = () => {
        this.toasterOpen = false
    }



}

export const UIStoreContext = createContext(new UIStore());
