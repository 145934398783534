export const BASE_URL = "api.admin.contrend.com"
export const BASE_PORT = "8000"
export const PROTOCOL = "https://"
//const ROOT_URL = `${PROTOCOL}${BASE_URL}:${BASE_PORT}/`;
const ROOT_URL = `${PROTOCOL}${BASE_URL}/`;


export const AuthUrls = {
    GET_TENANT: `${ROOT_URL}is-tenant/`,
    LOGIN: `api/token/`,
    LOGIN_REFRESH: 'api/token/refresh/',
    SIGNUP: `${ROOT_URL}rest-auth/registration/`,
    CHANGE_PASSWORD: `${ROOT_URL}rest-auth/password/change/`,
    RESET_PASSWORD: `${ROOT_URL}rest-auth/password/reset/`,
    RESET_PASSWORD_CONFIRM: `${ROOT_URL}rest-auth/password/reset/confirm/`,
    USER_ACTIVATION: `${ROOT_URL}rest-auth/registration/verify-email/`,
    USER_PROFILE: `${ROOT_URL}rest-auth/user/`
};

export const ResearchUrls = {
    GET_REPORT_FILTER_OPTIONS: `api/reports/research/filter/options/`,
    GET_REPORTS_FILTER: `api/reports/research/filter/`,

};

export const SharedUrls = {
    GET_MARKETS: `api/markets/`,
    GET_REGIONS: `${ROOT_URL}api/regions/`,
    GET_RESEARCH_SUBJECTS: `api/research_subjects/`,
    GET_RESEARCH: `api/research/`
};


export const get_base_url = (tenant) => {
    let url = ROOT_URL
    if (tenant !== 'public') {
        url = `${PROTOCOL}${tenant}.${BASE_URL}/`;
    }
    return url

}

export const LinksSideBar = {
    reports: '/researchManagement/reports',
    link: '/researchManagement/researchList',
    subject: '/researchManagement/researchSubject',
    dashboard: '/dashboard',
    body: '/',
    changePassword:'/changePassword',
    notifications: '/notifications',
    userGuide:'/userGuide',
    myProfile:'/profile',
}

export const onBreadCrumb = {
    list: 'RESEARCH LIST',
    research: 'RESEARCH',
    about:'ABOUT',
    projects:'PROJECTS',
    profile:'PROFILE',
    notification:'NOTIFICATION',
    reports:'REPORTS',
    researchSubject:'RESEARCH SUBJECT',
    changePassword:'CHANGE YOUR PASSWORD',

}