import { ResearchUrls, get_base_url } from '../constants/urls'
import axios from './axios'




export const get_reports = async (token, onSuccess, onFail, values, callback, tenant) => {
    const url = get_base_url(tenant) + ResearchUrls.GET_REPORTS_FILTER
    try {
        const { data } = await axios.post(url, values)
        onSuccess(data, callback)
    }
    catch (e) {
        onFail(e)
    }
}

export const get_reports_filter = async (token, onSuccess, onFail, filter, callback, tenant) => {
    const url = get_base_url(tenant) + ResearchUrls.GET_REPORT_FILTER_OPTIONS
    try {
        const { data } = await axios.post(url, filter)
        onSuccess(data, callback)
    }
    catch (e) {
        onFail(e)
    }
}

