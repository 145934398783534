import styled from 'styled-components';
import bgImg from '../../../assets/img/base-bg.jpg'

export const LoginContainer = styled.div `
    /* background: url('../../../assets/img/base-bg.jpg') no-repeat; */
    /* background-color: red; */
    width: 100vw;
    height: 100vh;
    background: url(${bgImg}) no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex-grow:1; */
    
`