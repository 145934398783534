import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    SideMenuContainer,
    SideMenuHeader,
    LabelSideBar,
    LinkSideBar,
    DropdownToggleSideBar,
    WrapperLinks
} from '../style/Main.styled';
import { theme } from '../../../style/Global'
import { Crosshair, ChevronDown } from "react-feather";
import { UncontrolledDropdown } from 'reactstrap';
import { useLocation } from "react-router-dom";
import { LinksSideBar } from '../../../constants/urls';
import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

const meEnv = dotenv.config()
dotenvExpand(meEnv)

const mode = process.env.REACT_APP_PRODUCTION_MODE ? JSON.parse(process.env.REACT_APP_PRODUCTION_MODE) : false


const SideMenu = observer((props) => {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <SideMenuContainer theme={theme}>
            <UncontrolledDropdown isOpen={isOpen}>

                <DropdownToggleSideBar tag="nav">
                    <SideMenuHeader onClick={toggle} className={`${isOpen ? 'active' : ''}`} >
                        <Crosshair size={18} />
                        <LabelSideBar>Research Management</LabelSideBar>
                        <ChevronDown />
                    </SideMenuHeader>

                </DropdownToggleSideBar>

                {isOpen &&
                    <WrapperLinks>
                        {!mode &&
                        <>
                        </>
                        }

                        <LinkSideBar
                        style={{ outline: 'none' }}
                        to={{
                            pathname: LinksSideBar.reports,
                            state: { from: location }
                        }}>Reports</LinkSideBar>
                    </WrapperLinks>
                }
            </UncontrolledDropdown>
        </SideMenuContainer>
    )
})

export default SideMenu
