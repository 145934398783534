import { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
import { MainContainer, MainBodyContainer, MainBody } from './style/Main.styled';
import TopNavBar from './components/TopNavBar';
import SideMenu from './components/SideMenu';
import { theme } from '../../style/Global'
import Toaster from '../Global/Toaster';
import { LinksSideBar } from '../../constants/urls';

const Reports = lazy(() => import('../Reports'));
const List = lazy(() => import('../ResearchList'));
const Subjects = lazy(() => import('../ResearchSubject'));
const ChangePassword = lazy(() => import('../ChangePassword'));
const Notification = lazy(() => import('../Notification'));
const MyProfile = lazy(() => import('../MyProfile'));

const override = css`
  display: block;
  margin: auto;
  border-color: red;
`;


const Main = observer((props) => {
    const { pathname } = useLocation()
    return (
        <MainContainer>
            <TopNavBar />
            <MainBodyContainer>
                <SideMenu />
                <Suspense fallback={<RingLoader css={override} />}>
                    <MainBody theme={theme}>
                        {pathname === LinksSideBar.reports && <Reports />}
                        {pathname === LinksSideBar.link && <List />}
                        {pathname === LinksSideBar.subject && <Subjects />}
                        {pathname === LinksSideBar.changePassword && <ChangePassword/>}
                        {pathname === LinksSideBar.notifications && <Notification/>}
                        {pathname === LinksSideBar.myProfile && <MyProfile />}
                        {/* {pathname === LinksSideBar.userGuide && <h1>user guide</h1>} */}
                        {/* {pathname === LinksSideBar.dashboard && <h1>dashboard</h1>} */}
                        {/* {pathname === LinksSideBar.body && <h1>Main body</h1>} */}
                    </MainBody>
                </Suspense>
            </MainBodyContainer>
            <Toaster />
        </MainContainer>
    )
})

export default Main