import { ErrorMessage, Field } from "formik";
import React, { Component } from "react";
import { FormGroup, Label } from "reactstrap";

export default class InputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: this.props.value !== "" ? "float-selected" : "",
      type: props.type,
      typeClass: "fa-eye-slash"
    };
  }

  addFloatClass = () => {
    this.setState({ activeClass: "float-active" });
  };

  removeFloatClass = e => {
    // e.target.value === "" ? this.setState({ activeClass: "" }) : this.setState({ activeClass: "float-active"  })
    this.setState({ activeClass: e.target.value === "" ? "" : "float-active" })
  };

  toggleType = () => {
    let typeClass =
      this.state.typeClass === "fa-eye" ? "fa-eye-slash" : "fa-eye";
    let type = this.state.type === "password" ? "text" : "password";
    this.setState({
      typeClass: typeClass,
      type: type,
      activeClass: "float-active"
    });
  };

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ activeClass: nextProps.value ? "float-selected" : this.state.activeClass })
  // }

  render() {
    let iconClass = this.props.showIcon === true ? "field-icon " : "";
    let { iconName, isMandatory } = this.props;
    //eslint remove isPassword from this.props
    iconClass =
      this.props.errors &&
        this.props.errors[this.props.name] !== undefined &&
        this.props.touched[this.props.name] === true
        ? `${iconClass} error `
        : iconClass;
    return (
      <FormGroup
        className={
          `floating-label password ${iconClass} ${this.state.activeClass}`
        }
      >
        {this.props.showIcon && (
          <div className="icon">
            <i className={iconName} aria-hidden="true" />
          </div>
        )}
        <Label className="floating-text" for={this.props.id}>
          {this.props.placeholder}
          {isMandatory && <span className={"danger"}>*</span>}
        </Label>
        <Field
          type={this.state.type}
          name={this.props.name}
          id={this.props.id}
          className="form-control"
          onFocus={this.addFloatClass}
          value={this.props.value}
          onBlur={e => {
            this.props.handleBlur && this.props.handleBlur(e);
            this.removeFloatClass(e);
          }}
          validate={this.props.validateField}
          disabled={this.props.disabled}
          onKeyPress={this.props.onKeyPress}
        // onChange={this.props.onChange}
        />
        {/* {isPassword && (
          <Button type="button" className="pass-eye" onClick={this.toggleType}>
            <i className={"fa " + this.state.typeClass} aria-hidden="true" />
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
          </Button>
        )} */}
        <ErrorMessage
          name={this.props.name}
          component={"div"}
          className="error"
        />
      </FormGroup>
    );
  }
}
