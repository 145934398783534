import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { UserStoreContext } from './stores/UserStore'
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Login from './Components/Authentication/Login'
import Main from './Components/Main/Main'
import { ThemeProvider } from 'react-bootstrap';
import { GlobalStyles, theme } from './style/Global'

const App = observer(() => {
  const userStore = useContext(UserStoreContext)
  const [isAuthenticated, setIsAuthenticated] = useState(userStore.isAuthenticated)



  useEffect(() => {
    setIsAuthenticated(userStore.isAuthenticated)

  }, [userStore.isAuthenticated])

  return (
    // <GlobalStyles>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles theme={theme} />
        <Router>
          <Route exact path="/">
            {isAuthenticated ? <Main /> : <Redirect to="/login" />}
          </Route>
          <Route
            path="/researchManagement/:select"
            exact
            render={props => (
              isAuthenticated ? <Main {...props} /> : <Redirect to="/login" />
            )
            }
          />
          <Route
            path="/:select"
            exact
            render={props => (
              isAuthenticated ? <Main {...props} /> : <Redirect to="/login" />
            )}
          />
          <Route exact path="/login">
            <Login />
          </Route>
        </Router>
      </>
    </ThemeProvider>
    // </GlobalStyles>
  );
})

export default App
